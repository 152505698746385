html, #root{
  height: 100%;
  /* background: linear-gradient(176deg, #2b1b35 0%, #090a0f 10%, #090a0f 84%, #30273d 100%); */
  overflow: hidden;
  background-color:#0d0414;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}



.AppWrapper h2{
  padding:16px 20px 24px;
  color:#fff;
  font-family: "Open Sans", sans-serif;
  font-size: 52px;
  margin:0px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.AppWrapper h3{
  padding:56px;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.top-title{
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-size: 40px;
  color:#fff;
  text-align: center;
}
.wayRow{
  display: flex;
  flex-direction: row;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  gap:8px;
  align-items: center;
}

.wayRow .small{
  font-size: 12px;
  color:#fff;
}
.wayRow .small2{
  font-size: 16px;
  color:#fff;
}
.wayRow .medium{
  font-size: 24px;
  color:#fff;
}
.wayRow .big{
  font-size: 32px;
  color:#fff;
}
.wayRow .big2{
  font-size: 40px;
  color:#fff;
}
.wayRow .text{
  font-size: 40px;
  color:#fff;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}


@media (max-width: 756px) {
  .AppWrapper h1 {
    font-size: 62px;
    padding-top:64px;
  }
  .AppWrapper h2{
    flex-direction: column;
  }
}
@media (max-width: 620px) {
  .AppWrapper h1 {
    font-size: 46px;
  }
  .top-title{
    font-size: 32px;
  }
  .wayRow{
    flex-direction: column;
    gap:0;
  }
}
@media (max-width: 480px) {
  .AppWrapper h1 {
    font-size: 36px;
  }
  .top-title{
    padding:0 56px;
  }
}